$(document).ready(function () {
  store_name = $("#store_name").val();
  price = $("#product_price_input").val();

  var numericPart = price.replace(/[^0-9.]/g, '');
  var parts = numericPart.split('.');
  if(parts.length == 1) {
    var integerValue = parts[0];
  } else {
    var integerValue = parts[0] + parts[1];
  }
  
  var data = JSON.stringify({
    "store_name": store_name
  });

  function formatNumber(number) {
    if (Number.isInteger(number)) {
        return number.toFixed(2);
    } else if (number.toFixed(1) === number.toString()) {
        return number.toFixed(2);
    } else {
        return number.toString();
    }
}

  var xhr = new XMLHttpRequest();
  
  xhr.addEventListener("readystatechange", function() {
    if(this.readyState === 4) {
      var jsonResponse = JSON.parse(this.responseText);
      var percentage = jsonResponse.percentage;
      var bonus = (integerValue * percentage) / 100;
      var background_color = jsonResponse.background_color;
      var text_color = jsonResponse.text_color;
      var product_choose = jsonResponse.product_choose;
      var status = jsonResponse.status;
      var url = jsonResponse.url;
      var html = '<a href="' + url + '" class="sale-price-text test" style="padding: 4px 20px;color: ' + text_color + ';background: ' + background_color + ';display: inline-block;margin: 14px 0;border-radius: 5px;"> Opptjen ' + formatNumber(bonus) +' kr i bonus</a>';
      // if(status == 1) {
        if(product_choose == 'all') {
          document.getElementById('bonus-tag-for-all').innerHTML = html;
        } else if(product_choose == 'specific') {
          document.getElementById('bonus-tag-for-original').innerHTML = html;
        } else {
          document.getElementById('bonus-tag-for-sale').innerHTML = html;
        }
      // }
    }
  });
  
  xhr.open("POST", "https://dialog.modish.no/api/tag-attributes");
  xhr.setRequestHeader("Content-Type", "application/json");
  
  xhr.send(data);
  
  
     
  
});